<!-- 修改密码 -->
<template>
    <div class="update_pwd">
        <div class="content margin_left_30">

            <!-- 标题 -->
            <h3>修改系统用户账号密码</h3>

            <el-card class="box margin_top_30">
                <div class="top">
                    <!--  用户名 -->
                    <div class="com_dv"><span class="min_dv">用户名：</span>{{name}}</div>

                    <!-- 手机号 -->
                    <div class="com_dv margin_top_30"><span class="min_dv">登录手机号：</span>{{tel}}</div>

                    <!-- 新密码 -->
                    <div class="com_dv margin_top_30">
                        <div class="min_dv">
                            <span class="tv_red">*</span>
                            <span>新密码：</span>
                        </div>
                        <el-input maxlength="20" clearable v-model="newPwd" placeholder="输入密码"></el-input>
                    </div>

                    <!-- 确认新密码 -->
                    <div class="com_dv margin_top_30">
                        <div class="min_dv">
                            <span class="tv_red">*</span>
                            <span>确认密码：</span>
                        </div>
                        <!-- type="password" -->
                        <el-input maxlength="20" clearable v-model="confirmPwd" placeholder="输入确认密码"></el-input>
                    </div>
                </div>
                <div class="bom">
                    <el-button :loading="showLoading" :class="['commit_bt','bt_active']" @click.stop="doSaveAction">保&nbsp;&nbsp;&nbsp;&nbsp;存</el-button>
                    <el-button :class="['commit_bt','bt_normal']" @click.stop="doCancelAction">取&nbsp;&nbsp;&nbsp;&nbsp;消</el-button>
                </div>
            </el-card>

        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            //输入的新密码
            newPwd: "",

            //确定的新密码
            confirmPwd: "",

            //加载框值
            showLoading: false,

            //被修改人的姓名,手机号,id
            name: "",
            tel: "",
            id: "",
        };
    },
    created() {
        this.initData();
    },
    methods: {
        //获取传递的值
        initData() {
            this.name = this.$route.query.name;
            this.tel = this.$route.query.tel;
            this.id = this.$route.query.id;
        },

        //保存的操作
        doSaveAction() {
            if (this.isEmpty(this.newPwd)) {
                this.showWarnMsg("请输入密码");
                return;
            }

            if (!this.testPwd(this.newPwd)) {
                this.showWarnMsg(
                    "密码格式错误,必须为6~20位数字、字母或者数字+字母的组合且不能包含特殊字符"
                );
                return;
            }

            if (this.isEmpty(this.confirmPwd)) {
                this.showWarnMsg("请输入确认密码");
                return;
            }

            if (this.confirmPwd != this.newPwd) {
                this.showWarnMsg("两次输入的密码不一致");
                return;
            }

            //加载
            this.showLoading = true;

            let param = this.getHttpParams();
            param.type = 4;
            param.pw = this.newPwd;
            param.id = this.id;

            this.$http
                .fetchPost(this.$api.ADMINMANAGE, param)
                .then((res) => {
                    let result = res.data;
                    this.showLoading = false;
                    if (result.state == 200) {
                        //请求成功
                        this.showSuccessMsg("修改成功,请重新登录");

                        //判断一下修改的是不是自己账号密码
                        if (this.id == this.getUserInfo().id) {
                            //表示是修改的自己密码,返回登录界面
                            this.$router.replace("/");
                        } else {
                            this.$router.go(-1);
                        }
                    } else {
                        this.showErrorMsg(`${result.msg},修改失败`);
                    }
                })
                .catch((err) => {
                    this.showLoading = false;
                    console.log("请求数据异常");
                });
        },

        //取消的操作
        doCancelAction() {
            //返回
            this.$router.go(-1);
        },
    },
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.update_pwd {
    height: calc(100vh - 87px);
    display: flex;
    // align-items: center;
    // justify-content: center;
    .content {
        .box {
            // padding: 30px 80px;
            width: 510px;
            padding: 20px;
            .com_dv {
                display: flex;
                align-items: center;
                .min_dv {
                    text-align: right;
                    width: 120px;
                }
                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                    padding-left: 6px;
                }

                ::v-deep .el-input__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                ::v-deep .el-input {
                    width: 210px;
                }

                ::v-deep .el-input__suffix {
                    right: 0px;
                }
            }

            .bom {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 40px;

                .bt_normal {
                    background: #cfd1d4;
                    color: white;
                    margin-left: 50px;
                }

                .bt_active {
                    background: #409eff;
                    color: white;
                    cursor: pointer;
                }

                .commit_bt {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    width: 100px;
                    border: none;
                }
            }
        }
    }
}
</style>